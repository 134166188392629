import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import Layout from '../components/layout'

const TeachingPage = ({ data }) => {
	return  (
    <Layout pageTitle={`${data.mdx.frontmatter.courseCode} ${data.mdx.frontmatter.thaiTitle} (${data.mdx.frontmatter.title}) - ${data.mdx.frontmatter.seasonYear}`}>
      <h1 className="mb-1">{data.mdx.frontmatter.courseCode} {data.mdx.frontmatter.thaiTitle} ({data.mdx.frontmatter.title})</h1>
			<h2 className="text-zinc-500">{data.mdx.frontmatter.seasonYear}</h2>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}
export const q = graphql`
query teachingQuery($id: String!) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      date
      courseCode
      seasonYear
			thaiTitle
    }
  }
}`

export default TeachingPage